import React, { Component } from "react";
import { Input, Row, Col, FormGroup } from "reactstrap";
import Tab from 'react-bootstrap/Tab';
import { Nav,Form } from 'react-bootstrap';
import { getCookie } from 'cookies-next';
import axios from 'axios';
import {UserProfile} from '../../../lib/Endpoints';

export default class Subscriber extends Component {
	constructor(props) {
		super(props);
		this.state = {
			firstName: '',
			lastName: '',
			phoneNo: '',
			loginEmail: '',
			emailErr: '',
			sendEmail: true,
			firstNameErr: '',
			lastNameErr: '',
			phoneErr: '',
			passwordErr: '',
			user: getCookie('token'),
			isExistUser:true,
			password: '',
			errLogin:'',
			userResult: '',
		};

		this.onChangeEmail = this.onChangeEmail.bind(this);
		this.onChangeSendEmail = this.onChangeSendEmail.bind(this);
		this.onChangeFirstName = this.onChangeFirstName.bind(this);
		this.onChangeLastName = this.onChangeLastName.bind(this);
		this.onChangePhone = this.onChangePhone.bind(this);
		this.signupTabClick = this.signupTabClick.bind(this);
		this.loginTabClick = this.loginTabClick.bind(this);
		this.onChangePassword = this.onChangePassword.bind(this);
		this.onChangeSignUpEmail = this.onChangeSignUpEmail.bind(this);
	}

	loginTabClick()
	{
		this.setState({
			isExistUser: true,
			emailErr: '',
			firstNameErr: '',
			lastNameErr: '',
			phoneErr: '',
			firstName: '',
			lastName: '',
			loginEmail: '',
			phoneNo: '',
			signupEmail: '',
			password:'',
			errLogin:'',
		},() => {
			this.props.validationHandler();
			this.props.handler('is_exist_user', true);
			
		});
	}

	signupTabClick()
	{
		this.props.validationHandler();
		this.setState({
			isExistUser: false,
			emailErr: '',
			firstNameErr: '',
			lastNameErr: '',
			phoneErr: '',
			firstName: '',
			lastName: '',
			loginEmail: '',
			phoneNo: '',
			signupEmail: '',
			password:'',
		}, () => {
			this.props.handler('is_exist_user', false);
		});
	}

	componentDidMount() {
		this.setState({
			emailErr: this.props.state.emailErr,
			firstNameErr: this.props.state.firstNameErr,
			lastNameErr: this.props.state.lastNameErr,
			phoneErr: this.props.state.phoneErr,
			errMsgPwd: this.props.state.errMsgPwd,
			phoneNo: '',
			signupEmail: '',
			password:'',
			errLogin:this.props.state.errLogin,
		})

		this.props.handler('is_exist_user', true);
		// if(this.state.user) {
			this.getUserProfile();
		// }		
	}

	getUserProfile() {
		let token =  getCookie('token');
		if(token) {
			
			const base64 = require('base-64');

			axios.get(UserProfile, { headers: {
	            "Authorization" : 'Bearer '+ base64.decode(token),  "Accept": "application/json" }
	        }).then((response) => {
	        	if(response.data) {
	        		let result = response.data;
	        		this.setState({
						firstName: result.first_name,
						lastName: result.last_name,
						loginEmail: result.email,
					});

	        	}

	        },(error) => {
	        });
		}
	}

	componentDidUpdate(previousProps, previousState) {
		if (previousProps.state !== this.props.state) {
			this.setState({
				emailErr: this.props.state.emailErr,
				firstNameErr: this.props.state.firstNameErr,
				lastNameErr: this.props.state.lastNameErr,
				phoneErr: this.props.state.phoneErr,
				errMsgPwd: this.props.state.errMsgPwd,
				errLogin:this.props.state.errLogin,
			});
			this.getUserProfile();
		}	
	}

	onChangeEmail(e){
		if(e) {
			this.setState({emailErr: '', loginEmail: e, errLogin:''});
		}else {
			this.setState({loginEmail: ''});
		}
		this.props.handler('email', e);

		this.props.handler('is_exist_user', this.state.isExistUser);
	}

	onChangeSignUpEmail(e) 
	{
		if(e) {
			this.setState({emailErr: '', signupEmail: e});
		}else {
			this.setState({signupEmail: ''});
		}
		this.props.handler('email', e);

		this.props.handler('is_exist_user', this.state.isExistUser);
	}

	onChangePassword(e) {
		if(e) {
			this.setState({passwordErr: '', password: e, errLogin:''});
		}else {
			this.setState({password: ''});
		}
		this.props.handler('password', e);
		this.props.handler('phone_number', '');
		this.props.handler('first_name','');
		this.props.handler('last_name','');
		this.props.handler('is_exist_user', this.state.isExistUser);
	}

	onChangeFirstName(e){
		if(e) {
			this.setState({firstNameErr: '', firstName: e});
		}else {
			this.setState({firstName: ''});
		}
		this.props.handler('first_name',e);
		this.props.handler('is_exist_user', this.state.isExistUser);
	}

	onChangeLastName(e)
	{
		if(e) {
			this.setState({lastNameErr: '', lastName: e});
		}else {
			this.setState({lastName: ''});
		}

		this.props.handler('last_name', e);
		this.props.handler('is_exist_user', this.state.isExistUser);
	}

	onChangePhone(e){
		if(e) {
			this.setState({phoneErr: '', phoneNo: e});
			this.props.handler('phone',e);
			this.props.handler('is_exist_user', this.state.isExistUser);
		}
	}

	onChangeSendEmail(e) {
		this.setState({sendEmail: e.target.checked});
		this.props.handler('send_email', e.target.checked);
	}

	loginForm()
	{
		let inputProps = {
			checked: false
		};
		if (this.state.sendEmail === true){
			inputProps.checked = true;
		}
		return (
			<Form className="containers" autoComplete="off">
				<FormGroup>
					<Input
						type="text"
						name="login-email"
						placeholder="Enter Email Address"
						className="input_text"
						autocomplete="off"
						disabled={this.state.user && this.state.user.email ? true : false}
						defaultValue={this.state.loginEmail ? this.state.loginEmail : ''}
						onChange={(e) => this.onChangeEmail(e.target.value)}
					/>
					<span className="validation_error">
						{
							this.state.isExistUser && this.state.emailErr 
							? this.state.emailErr
							: ''
						}
					</span>
				</FormGroup>
				<FormGroup>
					<Input
						type="password"
						name="password"
						placeholder="Enter Password"
						className="input_text"
						defaultValue={this.state.password ? this.state.password : ''}
						onChange={(e) => this.onChangePassword(e.target.value)}
					/>
					<span className="validation_error">
						{
							this.state.isExistUser && this.state.errMsgPwd 
							? this.state.errMsgPwd
							: ''
						}
					</span>
				</FormGroup>
				<span className="validation_error">
						{
							this.state.isExistUser && this.state.errLogin 
							? this.state.errLogin
							: ''
						}
					</span>
				
				<FormGroup>
					<div className="checkbox__field_c">
						<input id="send_email" type='checkbox' name="send_email" onChange={this.onChangeSendEmail} {...inputProps}/>
						<label htmlFor="send_email">I am happy to receive occasional marketing emails from Toprow.</label>
					</div>
				</FormGroup>
			</Form>
		)
	}

	loginUserForm()
	{
		let inputProps = {
			checked: false
		};
		if (this.state.sendEmail === true){
			inputProps.checked = true;
		}
		return (
			<Form className="containers" autoComplete="off">
				<Row>
					<Col md="6">
					<FormGroup>
						<Input
						type="text"
						name="first_name"
						placeholder="Enter First Name"
						className="input_text"
						disabled={this.state.firstName && this.state.firstName ? true : false}
						defaultValue={this.state.firstName ? this.state.firstName : ''}
						onChange={(e) => this.onChangeFirstName(e.target.value)}
					/>
					<span className="validation_error">
							{
								this.state.isExistUser && this.state.firstNameErr 
								? this.state.firstNameErr
								: ''
							}
						</span>

					</FormGroup>
					</Col>
						<Col md="6">
					<FormGroup>
						<Input
							type="text"
							name="last_name"
							placeholder="Enter Last Name"
							className="input_text"
							disabled={this.state.lastName && this.state.lastName ? true : false}
							value={this.state.lastName }
							onChange={(e) => this.onChangeLastName(e.target.value)}
						/>
						<span className="validation_error">
							{
								this.state.isExistUser && this.state.lastNameErr 
								? this.state.lastNameErr
								: ''
							}
						</span>

					</FormGroup>
					</Col>
				</Row>
				<FormGroup>
					<Input
						type="text"
						name="login_email"
						placeholder="Enter Email Address"
						className="input_text"
						disabled={this.state.loginEmail && this.state.loginEmail ? true : false}
						value={this.state.loginEmail ? this.state.loginEmail : ''}
						onChange={(e) => this.onChangeEmail(e.target.value)}
					/>
					<span className="validation_error">
						{
							this.state.isExistUser && this.state.emailErr 
							? this.state.emailErr
							: ''
						}
					</span>
				</FormGroup>
				<FormGroup>
					<div className="checkbox__field_c">
						<input id="send_email_login" type='checkbox' name="send_email" onChange={this.onChangeSendEmail} {...inputProps}/>
						<label for="send_email_login">I am happy to receive occasional marketing emails from Toprow.</label>
					</div>
				</FormGroup>
			</Form>
		)
	}

	registerForm()
	{
		let inputProps = {
			checked: false
		};
		if (this.state.sendEmail === true){
			inputProps.checked = true;
		}
		return (
			<Form className="containers" autoComplete="off">
				<Row>
					<Col md="6">
					<FormGroup>
						<Input
						type="text"
						name="first_name"
						placeholder="Enter First Name"
						className="input_text"
						defaultValue={this.state.firstName ? this.state.firstName : ''}
						onChange={(e) => this.onChangeFirstName(e.target.value)}
					/>
					<span className="validation_error">
						{!this.state.isExistUser && this.state.firstNameErr 
							? this.state.firstNameErr
							: ''
						}
					</span>
					</FormGroup>
					</Col>
						<Col md="6">
					<FormGroup>
						<Input
							type="text"
							name="last_name"
							placeholder="Enter Last Name"
							className="input_text"
							defaultValue={this.state.lastName ? this.state.lastName : ''}
							onChange={(e) => this.onChangeLastName(e.target.value)}
						/>
					<span className="validation_error">
						{
							!this.state.isExistUser && this.state.lastNameErr 
							? this.state.lastNameErr
							: ''
						}
					</span>
					</FormGroup>
					</Col>
				</Row>
				<FormGroup>
					<Input
						type="number"
						name="phone_number"
						placeholder="Enter Phone No."
						className="input_text phone-text-field"
						defaultValue={this.state.phoneNo}
						onChange={(e) => this.onChangePhone(e.target.value)}
					/>
					<span className="validation_error">{this.state.phoneErr}</span>
				</FormGroup>
				<FormGroup>
					<Input
						type="text"
						name="email"
						placeholder="Enter Email Address"
						className="input_text"
						defaultValue={this.state.signupEmail ? this.state.signupEmail : ''}
						onChange={(e) => this.onChangeSignUpEmail(e.target.value)}
					/>
					<span className="validation_error">
						{
							!this.state.isExistUser && this.state.emailErr 
							? this.state.emailErr
							: ''
						}
					</span>
				</FormGroup>
				<FormGroup>
					<div className="checkbox__field_c">
						<input id="send_email" type='checkbox' name="send_email" onChange={this.onChangeSendEmail} {...inputProps}/>
						<label for="send_email">I am happy to receive occasional marketing emails from Toprow.</label>
					</div>
				</FormGroup>
			</Form>
		)
	}

	checkUserIsLogin()
	{
		if(this.state.user){
			return this.loginUserForm();
		}else {
			return (
				<Tab.Container id="company_profile_tabs" defaultActiveKey="tab1">
					<Nav variant="pills" className="flex-row login_ques_tabs">
						<ul className="tab_option_list">
							<li>
								<Nav.Item>
									<Nav.Link eventKey="tab1" onClick={this.loginTabClick}>
										Login
									</Nav.Link>
								</Nav.Item>
							</li>
							<li>
							<Nav.Item>
								<Nav.Link eventKey="tab2" onClick={this.signupTabClick}>
								  Register
								</Nav.Link>
							</Nav.Item>
							</li>
						</ul>
					</Nav>
					<Tab.Content>
						<Tab.Pane eventKey="tab1">
							{this.loginForm()}
						</Tab.Pane>
						<Tab.Pane eventKey="tab2">
							{this.registerForm()}
						</Tab.Pane>
					</Tab.Content>
				</Tab.Container>
			)	
		}
	}
	
	render() {
		
		return (
			this.checkUserIsLogin()
		);
	}
}