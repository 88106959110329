import React, { Component } from 'react';
import { Modal,Button} from "react-bootstrap";
import Subscriber from './Subscriber';
import axios from 'axios';
import FlashState from "../../Common/FlashState";
import { Progress } from 'reactstrap';
import QuitQuentionPopup from './QuitQuentionPopup';
import {QuoteSendQuote, QuoteChildQuestion, QuoteParentQuestion} from '../../../lib/Endpoints';
import { setCookie, getCookie, deleteCookie } from 'cookies-next';
import {UserProfile} from '../../../lib/Endpoints';

export default class QuestionPopup extends Component {

	constructor(props) {
		super(props)
		this.state = {
			response: [],
			name: '',
			option: '',
			levelId: 1,
			previousLevel: [],
			previousLevelOption: [],
			parentId: '',
			lastRecord: '',
			lastRecordId: '',
			subscriberEmail: false,
			questionId: '',
			quote: [],
			isLoading: false,
			buttonText: 'Continue',
			emailAddress: '',
			user: null,
			endpoint: QuoteSendQuote,
			validationErr: '',
			formIsValid: '',
			progressBar: 10,
			answerId: '',
			previousOptionRecord: '',
			displayBtn: 'none',
			openQuitQuestionModal: false,
			emailErr: '',
			optionType: false,
			multiAnswers: [],
			categoryId: '',
			sendEmail: true,
			firstNameErr: '',
			lastNameErr: '',
			phoneErr: '',
			agencyId: '',
			postalCode: '',
			singleQuestion:QuoteChildQuestion,
			singleParentQuestion:QuoteParentQuestion,
			checkedId: '',
			checkedClass: 'isNotChecked',
			previousQuote: [],
			previousQuoteAnswer: [],
			backBtnOption:false,
			phone_number: '',
			inputFieldDisable: 'disabled',
			otherValue: '',
			otherFieldErr: '',
			progressBarShow: true,
			showConfirmMsg: false,
			removeClass: false,
			isExistUser: false,
			password:'',
			passwordErr:'',
			errMsgPwd:'',
			errLogin: '',
			loaderClass: '',
			latitude: '',
			longitude: '',
			otherInputHide: false,
			is_multi_option: false,
		}

		this.setOptions = this.setOptions.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.setQuestionHandler = this.setQuestionHandler.bind(this);
		this.handler = this.handler.bind(this);
		this.setAnswersParams = this.setAnswersParams.bind(this);
		this.saveAnswerHandler = this.saveAnswerHandler.bind(this);
		this.progressBarHandler = this.progressBarHandler.bind(this);
		this.onPreviousButton = this.onPreviousButton.bind(this);
		this.setAnswers = this.setAnswers.bind(this);
		this.setPreviousAnswers = this.setPreviousAnswers.bind(this);
		this.closeQuestionPopup = this.closeQuestionPopup.bind(this);
		this.quitPopupHandler = this.quitPopupHandler.bind(this);
		this.setMultipleAnswerParams = this.setMultipleAnswerParams.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onSignInHandler = this.onSignInHandler.bind(this);
		this.validationHandler = this.validationHandler.bind(this);
	}

	componentDidMount() {
		if(!this.props.state.lastRecord.attributes) {
			this.props.handler(true);

		} else {
			let result = this.props.result;

			const lastRecordId = this.props.state.lastRecord.attributes.level_id;
			this.setState({ 
				response: this.props.result,
				lastRecordId: lastRecordId,
				lastRecord: this.props.state.lastRecord,
				progressBar: (100 - (lastRecordId * this.state.progressBar)),
				categoryId: parseInt(this.props.state.categoryId),
				agencyId: this.props.state.agencyId,
				postalCode: this.props.state.postalCode,
				latitude: this.props.state.latitude,
				longitude: this.props.state.longitude,
			}, () => {
				this.setQuestionHandler();
			});

		}
	}

	componentDidUpdate(previousProps, previousState) {
		if(this.props.result.length) {
			if (previousProps.state !== this.props.state) {
				const lastRecordId = this.props.state.lastRecord.attributes ? this.props.state.lastRecord.attributes.level_id : '';
				this.setState({
					response: this.props.result,
					lastRecordId: lastRecordId,
					lastRecord: this.props.state.lastRecord,
					checked: '',
					categoryId: parseInt(this.props.state.categoryId),
					postalCode: this.props.state.postalCode,
					agencyId: this.props.state.agencyId,
				});
			}
		}
	}

	onChangeHandler(e) {
		this.setState({otherValue: e.target.value, formIsValid: true});
	}

	throwError(error) {
        
    }

	handleChange(event){
		let name = event.target.getAttribute("data-name");
		let valueName = '';
		if(!event.target.value) {
			this.setState({formIsValid: false});
		}
		if(event.target.type === 'checkbox') {
			if(name.toUpperCase() === 'OTHERS' || name.toUpperCase() === 'OTHER') {
				this.setState({inputFieldDisable: ''});
			}

			if(!event.target.checked) {
				if(Array.isArray(this.state.checkedId)) {
					let removeKey = this.state.checkedId.filter(data => {
						if(data !== event.target.value) return data;
					});
					this.setState({checkedId: removeKey, multiAnswers:removeKey}, () => {
						this.setState({formIsValid: this.state.checkedId.length});
					});
				}
			}
			
			if(event.target.checked) {
				this.setMultipleAnswerParams(event.target.value);
			}

			this.setOptions();

		} else if(event.target.type === 'radio') {
			if(name.toUpperCase() === 'OTHERS' || name.toUpperCase() === 'OTHER') {
				this.setState({inputFieldDisable: ''});

			} else {
				this.setState({
					inputFieldDisable: 'disabled', otherValue: ''
				});
			}
			this.setState({ 
				checkedId: event.target.value,
				formIsValid: true,
				checkedClass: 'isChecked',
			}, () => {
				this.setOptions();
				this.setAnswersParams(event.target.value);
				this.handler('parent_id', event.target.value);

			});
		}
	}

	setOptions() {
		if(this.state.option) {

			return this.state.option.map((options, index) => {
				let type = 'radio';
				if(this.state.is_multi_option) {
					type = 'checkbox';
				}

				let checkedClass = 'isNotChecked';

				if(options.name.toUpperCase() === 'OTHERS' ||
					options.name.toUpperCase() === 'OTHER') {
				}

				if(this.state.option.length === 1 && (options.name.toUpperCase() === 'OTHERS' ||
					options.name.toUpperCase() === 'OTHER')) {
					checkedClass = 'isChecked';
				}
				if(Array.isArray(this.state.checkedId) && 
						this.state.checkedId.includes(options.id.toString())) {
					checkedClass = 'isChecked'
				}else if(parseInt(this.state.checkedId) === parseInt(options.id)) {
					checkedClass = 'isChecked'
				}

				return (
					<div key="5" className="checkbox__field" style={{'display': this.state.otherInputHide ? 'none' : 'block'}}>	
						<input type={type}
							className={'form-check-input ' + checkedClass}
							name={'name' + this.state.levelId}
							value={options.id}
							data-name={options.name}
							checked={checkedClass === 'isChecked' ? true : false}
							onChange={(event) => this.handleChange(event)}
							id={options.id}
						/> 
						<label htmlFor={options.id}>
							{options.name}
						</label>
					</div>
				)
			})
		}else if(this.state.subscriberEmail){

			return <Subscriber state={this.state} handler={this.handler} validationHandler={this.validationHandler}/>
		}
	}


	validationHandler() {
		this.setState({
			emailErr: '',
			firstNameErr: '',
			lastNameErr: '',
			phoneErr: '',
			passwordErr: '',
			errLogin: '',
		});
	}

	checkedAnswers(optionId)
	{
		this.state.previousQuoteAnswer.filter((data) => {
			if(Array.isArray(optionId) && Array.isArray(data.answer_id) &&
					data.answer_id.includes(optionId[0]) ) {
				this.setState({
					checkedId: optionId,
					answerId: optionId,
					parentId: optionId,
					otherValue: data.value,
					inputFieldDisable: data.value ? '' : 'disabled',
					formIsValid: true
				});

			}else if (data.answer_id === optionId) {
				this.setState({
					checkedId: optionId,
					answerId: optionId,
					parentId: optionId,
					otherValue: data.value,
					inputFieldDisable: data.value ? '' : 'disabled',
					formIsValid: true
				});
				return true;
			}
			return false;
		});

	}

	onSubmit(e){
		if(this.state.buttonText === 'Submit') {
			this.setState({formIsValid: true, validationErr: '', displayBtn: 'none'});
		}

		if((this.state.option.length === 1) && (this.state.inputFieldDisable === '') && (this.state.otherValue === ''))  {
			this.setState({otherFieldErr: 'Please enter a value'})	
		}else if((this.state.option.length >= 1) && this.state.inputFieldDisable === '' && this.state.otherValue === '')  {
			this.setState({otherFieldErr: 'Please enter other option'})	

		} else {
			this.setState({otherFieldErr: ''})	
			if(this.state.formIsValid) {
				this.setAnswers();
				this.setPreviousAnswers();

				if(this.state.lastRecordId !== this.state.levelId) {
					this.setState({levelId: this.state.levelId+1, backBtnOption:false, formIsValid:false}, () => {
						this.requestToParentApi();
					});
				}

				if(this.state.showConfirmMsg && !this.state.subscriberEmail) {
					this.setState({
							showConfirmMsg: false,
							name: 'Are you sure want to submit?',
							option: '',
							formIsValid: true,
							progressBar: 100,
							removeClass:true,
					});
				}
				if(this.state.lastRecordId === this.state.levelId && !this.state.subscriberEmail && 
					!this.state.showConfirmMsg){
					this.setState({
						subscriberEmail: true,
						name: 'What email address would you like quotes sent to?',
						displayBtn: 'none',
						progressBarShow: false,
						buttonText: 'Submit',
						removeClass: false,
					}, () => {
						this.getUserProfile();
					});
				}
			}else {
				this.setState({validationErr: 'Please select an option'});
			}
		}

		if(this.state.buttonText === 'Submit'){
			this.onSignInHandler();
		}
	}

	setQuestionHandler(){

		if(this.state.levelId !== this.state.lastRecordId) {
			this.state.response.map((question, i) => {
				if(question.attributes.level_id === this.state.levelId && 
					!question.attributes.parent_id){

					this.setState({
						name: question.attributes.name,
						option: question.attributes.options,
						questionId: question.id,
						optionType:question.attributes.is_multi_option
					});

				}else if(question.attributes.parent_id && question.attributes.parent_id === this.state.parentId) {

					this.setState({
						name: question.attributes.name,
						option: question.attributes.options,
						questionId: question.id,
						optionType:question.attributes.is_multi_option
					});
				}
			});

			this.setState({
				validationErr:'',
				progressBar: this.state.progressBar+10,
			});
		}else if(this.state.subscriberEmail) {
			this.setState({
				subscriberEmail: true,
				name: '',
				option: '',
			});
		}

		if(this.state.levelId === this.state.lastRecordId && !this.state.subscriberEmail) {
			this.setState({
				name: this.state.lastRecord.attributes.name,
				option: this.state.lastRecord.attributes.options,
				validationErr:'',
				formIsValid:true,
				progressBar: 100,
				subscriberEmail: true,
			});
		}

		if(this.state.questionId) {
			const previousLevel = {'id':this.state.questionId};
			this.state.previousLevel.push(previousLevel);
		}
	}

	handler(key, val) {
		switch(key) {
			case 'error':
			this.setState({
				validationErr: val,
			});
			break;
			case 'parent_id':
			this.setState({
				parentId: val,
			});
			break;
			case 'button_text':
			this.setState({
				buttonText: val,
			});
			break;
			case 'email':
			this.setState({
				emailAddress: val,
				formIsValid:true,
				emailErr: '',
			});
			break;
			case 'first_name':
			this.setState({
				first_name: val,
				firstNameErr: '',
			});
			break;
			case 'last_name':
			this.setState({
				last_name: val,
				lastNameErr: '',
			});
			break;
			case 'phone':
			this.setState({
				phone_number: val,
				formIsValid:true,
				phoneErr: '',
			});
			break;
			case 'send_email':
			this.setState({
				sendEmail: val,
				formIsValid:true,
			});
			break;
			case 'is_exist_user':
			this.setState({
				isExistUser: val,
			});
			break;
			case 'password':
			this.setState({
				password: val,
			});
			break;
			default:
			break;
		}
	}

	setAnswersParams(value) {
		if(!value) {
			this.setState({formIsValid:false})
		}else{
			this.setState({
				formIsValid:true,
				validationErr: '',
				answerId: value,
				otherValue: this.state.otherValue
			})

			if(this.state.emailAddress) {
				this.setSubscriberEmail();
			}
		}
	}

	setMultipleAnswerParams(value) {
		this.state.multiAnswers.push(value);
		this.setState({formIsValid:true,
			checkedClass: 'isChecked',
			checkedId:this.state.multiAnswers
		})
		
		this.setAnswersParams(this.state.multiAnswers);
		this.handler('parent_id', this.state.multiAnswers[0]);
	}

	setAnswers() {
		if(this.state.answerId && !this.state.multiAnswers.length) {
			this.removeExistsRecordFromArr();
			const obj = {'question_id':this.state.questionId, 'value': this.state.otherValue, 'answer_id': parseInt(this.state.answerId)};
			const previousObj = {
				'question_id':this.state.questionId,
				'answer_id':Array.isArray(this.state.answerId) ? this.state.answerId : parseInt(this.state.answerId),
				'level_id': this.state.levelId,
				'value': this.state.otherValue,
			};

			this.state.quote.push(obj);
			this.state.previousQuote.push(previousObj);

			this.state.previousQuoteAnswer.push(previousObj);
			this.setState({answerId: '', otherValue: '', inputFieldDisable: 'disabled'});

		}else if(this.state.multiAnswers.length) {
			this.removeExistsRecordFromArr();
			const obj = {
				'question_id':this.state.questionId,
				'answer_id':this.state.multiAnswers,
				'value': this.state.otherValue,
				'level_id': this.state.levelId,
			};
			this.state.quote.push(obj);
			this.state.previousQuote.push(obj);

			this.state.previousQuoteAnswer.push(obj);
			this.setState({answerId: '', otherValue: '', inputFieldDisable: 'disabled'});
			this.setState({multiAnswers: []})
		}
	}

	removeExistsRecordFromArr(){
	
		// this.setState({quote});
		this.state.quote.map((data, index) => {
			if(parseInt(data.question_id) === parseInt(this.state.questionId)) {
				this.state.quote.splice(index, 1);
			}
		});

		this.state.previousQuote.map((data, index) => {
			if(parseInt(data.question_id) === parseInt(this.state.questionId)) {
				this.state.previousQuote.splice(index, 1);
			}
		});
	}


	setPreviousAnswers() {
		if(this.state.answerId) {
			const data = {'id':this.state.answerId, 'value': this.state.otherValue};
			this.state.previousLevelOption.push(data);
		}
	}

	onSignInHandler = () => {
		let user = getCookie('token');
		if(this.state.isExistUser && !user) {
	        this.setState({ isLoading: true, errLogin:'' });

	        axios.post(process.env.NEXT_PUBLIC_API_URL+"login", {
	            email: this.state.emailAddress,
	            password: this.state.password,
	        })
	        .then((response) => {
	            this.setState({ isLoading: false }, () => {
		            if (response.status === 200 && response.data.data.role_id !== 4) {
		                	this.setState({errLogin: 'You cannot send a quote with company account.'});
		                	// deleteCookie('token');

		            }else if(response.data.data.role_id === 4) {
		            	setCookie('token', response.data.data.access_token);
		                this.saveAnswerHandler();
		                this.setState({phone_number: '', errLogin:''});
		            }
	            });

	        }, (error) => {
	            if (error.response.status === 412) {
	                this.setState({
	                    emailErr: error.response.data.validation_error.email,
	                    errMsgPwd: error.response.data.validation_error.password,
	                    isLoading: false,
	                });

	                setTimeout(() => { this.setState({ errMsgEmail: "", errMsgPwd: "", }); }, 2000);
	            }else {
	            	this.setState({ errLogin: error.response.data.message,isLoading: false });
	            }
	            this.setState({ isLoading: false, errLogin:error.response.data.message, });

	        });
		}else {
			this.saveAnswerHandler();
		}
    }

	saveAnswerHandler(){
		
		if(!this.state.isLoading) {
			this.state.quote.sort((a, b) => (a.question_id - b.question_id));
			this.setState({ isLoading: true });
			const data = { 
				quote: this.state.quote,
				send_email: this.state.sendEmail,
				email: this.state.emailAddress,
				category_id: this.state.categoryId,
				first_name: this.state.first_name,
				last_name: this.state.last_name,
				agency_id: this.state.agencyId,
				postal_code: this.state.postalCode,
				is_exist_user: this.state.isExistUser,
				password: this.state.password,
				latitude: this.state.latitude,
				longitude: this.state.longitude,
			};

			if(this.state.phone_number) {
				data.phone = this.state.phone_number
			}

			axios.post(this.state.endpoint, data, {
				headers: {"Accept": "application/json", }
			}).then(res => {
				this.setState({
					response: [],
					name: '',
					option: '',
					levelId: 1,
					isLoading: false,
					previousLevel: [],
					previousLevelOption: [],
					parentId: '',
					lastRecord: '',
					lastRecordId: '',
					subscriberEmail: false,
					questionId: '',
					quote: [],
					buttonText: 'Continue',
					emailAddress: '',
					validationErr: '',
					formIsValid: '',
					progressBar: 10,
					answerId: '',
					previousOptionRecord: '',
					displayBtn: 'none',
					openQuitQuestionModal: false,
					emailErr: '',
					password: '',
					isExistUser: false,
				});
				FlashState.set('message', 'Thanks For Sending Quote!');
				FlashState.set('type', 'success');
				this.props.handler();
			},(error) => {
				if(error.response.status === 401) {
					FlashState.set('message', 'Un-authorize Access!');
					FlashState.set('type', 'error');
				} else if(error.response.status === 422) {
					this.setState({
						emailErr: error.response.data.errors.email,
						firstNameErr: error.response.data.errors.first_name,
						lastNameErr: error.response.data.errors.last_name,
						phoneErr: error.response.data.errors.phone,
						passwordErr: error.response.data.errors.password,
						isLoading: false,
					})
				}else {
					 this.throwError(error);
				}
				this.setState({
					isLoading: false,
				})
			});
		}
	}

	setSubscriberEmail() {
		const email = {'email':this.state.emailAddress};
		this.state.quote.push(email);
	}

	progressBarHandler(){
		if(this.state.progressBarShow) {
			return (<Progress className="success_progress-bar" value={this.state.progressBar}/>)
		}else{
			return ;
		}
	}

	onPreviousButton(){
		let previousOptionRecord = this.state.previousQuote.pop();
		if(previousOptionRecord && previousOptionRecord.answer_id) {
			this.checkedAnswers(previousOptionRecord.answer_id);
			this.setState({
				levelId: previousOptionRecord.level_id, 
				questionId:previousOptionRecord.question_id,
				backBtnOption: true,
				progressBarShow:true,
				removeClass:false,
				otherFieldErr: '',
				multiAnswers: [],
				progressBar: this.state.progressBar-10,
				}, ()=> {
				this.requestToChildApi();
			});
		}
	}

	checkParentOption(levelId, previousOptionRecord)
	{
		this.state.response.map((options) => {
			if(!options.attributes.parent_id && options.attributes.level_id === levelId) {
				options.attributes.options.map((subOptions) => {
					if(subOptions.id === parseInt(previousOptionRecord.id)) {
						this.setState({parentId: previousOptionRecord.id});
					}
				})
			}
		});

	}

	requestToChildApi() {
		this.setState({seletonLoading: true, loaderClass: 'quote_loader_remove'})
		axios.get(this.state.singleQuestion, {
			params: {
				service_id: this.state.categoryId,
				// parent_id: this.state.parentId,
				level_id: this.state.levelId,
				question_id: this.state.questionId,
			},
			headers: { "Accept": "application/json" }

		}).then(res => {
			let displayBtn = 'none';
			let subscriberEmail = false;
			let showConfirmMsg = false;
			let progressBarValue = this.state.progressBar-10; 
			if(res.data.data.attributes.level_id > 1) {
				displayBtn = 'inline-block';
			}
			if(res.data.data.attributes.level_id === this.state.lastRecordId) {
				showConfirmMsg = true;
			}

			if(progressBarValue <= 0) {
				progressBarValue = 10;
			}

			let options = res.data.data.attributes.options;
			if(options.length === 1) {
				let singlOption = options[options.length-1];

				if(singlOption.name.toUpperCase() === 'OTHERS' ||
					singlOption.name.toUpperCase() === 'OTHER') {
					this.setState({
						checkedId: singlOption.id,
						parentId:singlOption.id,
						inputFieldDisable: '',
						otherInputHide:true,
					}, () => {
						this.handler('parent_id', singlOption.id);
						this.setAnswersParams(singlOption.id);
					});
				}else {
					this.setState({
						inputFieldDisable: 'disabled',
						otherValue: '',
						otherInputHide:false,
					});
				}
			}else {
				this.setState({
					inputFieldDisable: 'disabled',
					otherValue: '',
					otherInputHide:false,
				});
			}

			if(res.data.data) {
				this.setState({
					name: res.data.data.attributes.name,
					option: res.data.data.attributes.options,
					levelId: res.data.data.attributes.level_id,
					is_multi_option: res.data.data.attributes.is_multi_option,
					questionId: res.data.data.id,
					progressBar: progressBarValue,
					displayBtn: displayBtn,
					previousState: true,
					buttonText: 'Continue',
					subscriberEmail: subscriberEmail,
					checkedClass: 'isNotChecked',
					formIsValid: true,
					seletonLoading: false,
					validationErr: '',
					showConfirmMsg:showConfirmMsg,
					loaderClass: '',
					grandParentId: res.data.data.attributes.grand_parent_id,
				});
			}

		},(error) => {
			this.setState({seletonLoading: false})
		});
	}

	requestToParentApi() {
		this.setState({isLoading: true});
		let parentId = this.state.parentId;
		if(Array.isArray(this.state.parentId)) {
			parentId = this.state.parentId[0];
		}
		axios.get(this.state.singleParentQuestion, {
			params: {
				service_id: this.state.categoryId,
				parent_id: parentId,
				level_id: this.state.levelId,
				
			},
			headers: { "Accept": "application/json" }

		}).then(res => {
			if(res.data.data) {
				let displayBtn = 'none';
				let subscriberEmail = false;
				let showConfirmMsg = false;
				let progressBarValue = 10;
				if(res.data.data.attributes.level_id > 1) {
					displayBtn = 'inline-block';
				}
				if(res.data.data.attributes.level_id === this.state.lastRecordId) {
					// subscriberEmail = true;
					showConfirmMsg = true;
				}

				if(this.state.lastRecordId <= 5) {
					progressBarValue = 20;
				}
				
				let options = res.data.data.attributes.options;
				if(options.length === 1) {
					let singlOption = options[options.length-1];

					if(singlOption.name.toUpperCase() === 'OTHERS' ||
						singlOption.name.toUpperCase() === 'OTHER') {
						this.setState({
							checkedId: singlOption.id,
							parentId:singlOption.id,
							inputFieldDisable: '',
							otherInputHide:true,
						},() => {
							this.handler('parent_id', singlOption.id);
							this.setAnswersParams(singlOption.id);
						});
					}else {
						this.setState({
							inputFieldDisable: 'disabled',
							otherValue: '',
							otherInputHide:false,
						});
					}
				}else {
					this.setState({
						inputFieldDisable: 'disabled',
						otherValue: '',
						otherInputHide:false,
					});
				}

				this.setState({
					name: res.data.data.attributes.name,
					option: res.data.data.attributes.options,
					levelId: res.data.data.attributes.level_id,
					questionId: res.data.data.id,
					is_multi_option: res.data.data.attributes.is_multi_option,
					progressBar: (res.data.data.attributes.level_id*progressBarValue),
					displayBtn: displayBtn,
					previousState: true,
					buttonText: 'Continue',
					subscriberEmail: subscriberEmail,
					checkedClass: 'isNotChecked',
					showConfirmMsg: showConfirmMsg,
					isLoading: false,
				});
			}else if(res.data.message === 'Not found!' && this.state.levelId === this.state.lastRecordId){
				this.setState({
					showConfirmMsg: false,
					name: 'Are you sure want to submit?',
					option: '',
					formIsValid: true,
					progressBar: 100,
					removeClass:true,
					isLoading: false
				});
			}else if(res.data.message === 'Not found!' && this.state.levelId <= this.state.lastRecordId) {
				this.setState({levelId: this.state.levelId+1, isLoading: false}, () => {
					this.requestToParentApi();
				})
			}
		},(error) => {
			this.setState({isLoading: false});

		});
	}

	closeQuestionPopup(key) {
		if(key === 'quit') {
			this.props.closeModal();
		}else if(key === 'continue') {
			this.setState({openQuitQuestionModal: false});
			this.props.displayNone(this.state.openQuitQuestionModal);
		}
	}

	quitPopupHandler() {
		this.setState({
			openQuitQuestionModal: !this.state.openQuitQuestionModal,
			progressBar:this.state.progressBar,
		});
		this.props.displayNone(this.state.openQuitQuestionModal);
	}

	flashMessageHandler() {
		this.props.handler();
	}


	getUserProfile() {
		let token = getCookie('token');
		if(token) {
			const base64 = require('base-64');
			axios.get(UserProfile, { headers: {
	            "Authorization" : 'Bearer '+ base64.decode(token),  "Accept": "application/json" }
	        }).then((response) => {
	        	if(response.data) {
	        		let result = response.data;
	        		this.setState({
						first_name: result.first_name,
						last_name: result.last_name,
						emailAddress: result.email,
					});
	        	}

	        },(error) => {
	        });
		}
	}

	render() {
		let className = "";
		if(this.state.removeClass) {
			className = "remove_option"; 
		}

		const styleCss = {
			'display': this.state.displayBtn,
		};
		  const isLoading = this.state.isLoading;
		return (
			<>
			{this.progressBarHandler()}
			<Modal.Header closeButton onClick={this.quitPopupHandler}>
			</Modal.Header>
				<Modal show={this.state.openQuitQuestionModal} className="quote__question_modal quit_ques_modal">
					<QuitQuentionPopup handler={this.closeQuestionPopup}/>
				</Modal>
			<Modal.Body>
				<Modal.Title className="text-center">
					{this.state.name}
				</Modal.Title>
				<div className="validation_error">{this.state.validationErr}</div>
				<div key='checkbox' className={className + " inner_ques-wrapper dashboard_loader " + this.state.loaderClass}>
					<div id="spinner" className="spinner_req_review" style={{"display": this.state.seletonLoading ? "block" : "none"}}>
							<div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
					</div>
					{this.setOptions()}
					{this.state.inputFieldDisable === '' ?
						<><input className="input_text"
							type="text" placeholder="Enter Here"
							value={this.state.otherValue} name="oher"
							disabled={this.state.inputFieldDisable}
							onChange={this.onChangeHandler} />
						<div className="validation_error">{this.state.otherFieldErr}</div></>
						: ''
					}
				</div>
			</Modal.Body>
			<Modal.Footer>
			<Button className="second-btn btn btn-primary" style={styleCss} onClick={this.onPreviousButton}>
					<span>Back</span>
				</Button>
				<Button className="primary-btn btn btn-primary" onClick={this.onSubmit}> 
					<span>{this.state.buttonText}</span>
					{isLoading 
						? (
							<span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true">
							</span>) 
						: (
							<span></span>
						)
						}
				</Button>
			</Modal.Footer>
			</>
			)
	}
}

