import React, { Component } from "react";
import { Modal,Button} from "react-bootstrap";
import axios from 'axios';
import FlashState from "../../Common/FlashState";
import QuestionsPopup from './QuestionsPopupNewStructure';
import CategoryOptionPopup from './CategoryOptionPopup';
import AgencyQuoteMessagePopup from './AgencyQuoteMessagePopup';
import NoQuesModelOpen from './NoQuesModelOpen';
import {AllCategories, QuoteQuestion, QuoteSearchAddress} from '../../../lib/Endpoints';
import { getCookie } from 'cookies-next';

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			service: '',
			response: '',
			isOpen: false,
			user:  null,
			endpoint: QuoteQuestion,
			categoryEndpoint: AllCategories,
			error: "",
			lastRecord: '',
			lastRecordId: '',
			buttonText: 'Continue',
			quote: [],
			questionId: '',
			emailAddress: '',
			displayBtn: 'flex',
			serviceId: '',
			postalCode: '',
			categories: [],
			isOpenCategoryModel:false,
			categoryId: '',
			categoryModelDisplay: true,
			isAgencyMessageModal:false,
			isNoQuesModalOpen: false,
			agencyId: '',
			countryCode: 'CA',
			isLoading: false,
			addressFindEndpoint: QuoteSearchAddress,
			isValidPostalCode: '',
			latitude: '',
			longitude: '',
			serviceIdValid: false

		};

		this.handler = this.handler.bind(this);
		this.displayNone = this.displayNone.bind(this);
		this.openQuestionModel = this.openQuestionModel.bind(this);
		this.modelQuitHandler = this.modelQuitHandler.bind(this);
	}

	componentDidMount() {

	}

	openModal = () => {
		if(!this.checkIsAgencyLogin()) {
			if(this.validateForm()) {

				this.setState({isLoading:true})
				const service = this.props.state.service.split(',');
					if(service.length > 1) {
						this.getCategoriesRequestToApi();
						this.setState({
							isOpenCategoryModel: true,
							agencyId: this.props.agencyId,
							postalCode: this.props.state.postalCode,
							isLoading: false,
					})

				}else if(!this.state.categories.length) {
				this.findAddressUsingPostalCode();
					this.setState({
						categoryId: this.props.state.service,
						agencyId: this.props.agencyId,
						postalCode: this.props.state.postalCode,
						serviceIdValid: true,
					}, () => {
						this.requestToApi();
					})
				}
			}
			
		}
	}

	openQuestionModel(categoryId)
	{
		this.setState({
			categoryId: categoryId,
			isOpenCategoryModel:false,
			isValidPostalCode: true,
			serviceIdValid: true,
		}, ()=> {
			this.requestToApi();
		});
	}

	closeModal = () => {
		this.setState({ 
			isOpen: false,
			isOpenCategoryModel:false,
			service: '',
			response: '',
			lastRecordId: '',
			lastRecord: '',
			agencyId: '',
			postalCode: '',
			categoryId: '',
			categories: [],
			isValidPostalCode:false,
			serviceIdValid:false,
		});
		this.props.handler();
		this.props.resetForm();
	}

	getCategoriesRequestToApi()
	{
		const ids = this.props.state.service.split(',');
		
		axios.get(this.state.categoryEndpoint, {
			params: {'categories_ids': ids},
			headers: { "Accept": "application/json" }

		}).then(res => {
			if(res.data.data.length) {
				this.setState({ 
					categories: res.data.data,
					postalCode: this.props.state.postalCode,
				});
			}else{
				FlashState.set('message', 'Record Not Found!!');
				FlashState.set('type', 'error');
			}
		},(error) => {
			FlashState.set('message', error.response.data.message);
			FlashState.set('type', 'error');
		});

	}

	checkIsAgencyLogin() {
		let token = getCookie('token');
    	let role = getCookie('user_role');

        if(token && (parseInt(role) !== 4)) {
            this.setState({
				isAgencyMessageModal: true,
				isOpen: false,
				isOpenCategoryModel: false,
				categoryModelDisplay: false,
				displayBtn: 'none',
			});

			return true;
        }

		return false;
	}

	checkIsQuestionIsExists() {
		if(!this.state.response.length) {
			this.setState({
				isAgencyMessageModal: false,
				isOpen: false,
				isOpenCategoryModel: false,
				categoryModelDisplay: false,
				displayBtn: 'none',
				isNoQuesModalOpen:true,
			});
		}
	}

	requestToApi() {
		if(this.state.isValidPostalCode && this.state.serviceIdValid) {
			this.setState({isLoading: true});
			axios.get(this.state.endpoint, {
				params: {service_id: this.state.categoryId},
				headers: { "Accept": "application/json" }

			}).then(res => {
				if(res.data.data.length) {
					this.setState({ 
						response: res.data.data,
					});
					var lastQues = res.data.data.pop();
					this.setState({ 
						lastRecordId: lastQues.attributes.level_id,
						lastRecord: lastQues,
						agencyId: this.props.agencyId,
						isOpen:true,
						isLoading: false,
					});
				}else{
					this.setState({ 
						response: [],
						serviceId: this.props.state.service,
						service: this.props.state.service,
						lastRecordId: '',
						agencyId: this.props.agencyId,
						isLoading:false,
					}, () => {
						this.checkIsQuestionIsExists();
					});
				}
			},(error) => {
				FlashState.set('message', error.response.data.message);
				FlashState.set('message', error.response.data.message);
				
				FlashState.set('type', 'error');
			});

		}
	}
	
	modelQuitHandler() {
		this.setState({
			categoryModelDisplay: !this.state.categoryModelDisplay,
		});
	}

	validateForm() {
		let errorsService = [];
		let postalCode = [];
		let formIsValid = true;
		if (!this.props.state.service) {
			formIsValid = false;
			errorsService = "Please select service!";
			this.props.handler('service_id', errorsService);
			this.setState({serviceIdValid : false});
		}

		if (!this.props.state.postalCode) {
			formIsValid = false;
			postalCode = "Please enter postal code!";
			this.props.handler('postal_code', postalCode);
		}

		return formIsValid;
	}

	findAddressUsingPostalCode()
	{
		if(this.props.state.postalCode) {
			this.setState({isLoading: true})
			axios.get(this.state.addressFindEndpoint +'?postal_code=' + this.props.state.postalCode, { 
				headers: {
					"Access-Control-Allow-Origin" : "*",
					"Accept": "application/json" 
				}

			}).then(res => {
			this.setState({isLoading: false})

				let result = res.data.data;

				if(result.is_address_find === false) {
					this.setState({isValidPostalCode: false})
				this.props.handler('postal_code', 'Invalid postal code!');

				}else if(result.is_address_find === true) {
					this.setState({isValidPostalCode: true, 
						latitude:result.latitude,
						longitude:result.longitude,
					}, () => {
						this.requestToApi();
					})
					this.props.handler('postal_code', '');
				}

			},(error) => {
				this.setState({isLoading: false})
				if(error.response.status === 412) {

					let validateErr = error.response.data.validation_error;

					if(validateErr.postal_code.length) {
						this.props.handler('postal_code', validateErr.postal_code[0]);
						this.setState({isValidPostalCode:false})

					}

				} else {
					this.setState({
						error: error.response.data.message
					});
				}

			});
		}
	}

	handler(value = false) {
		this.props.flashHandler();
		this.setState({isOpen: false});
	}

	displayNone(key) {
		if(this.state.displayBtn === 'none') {
			this.setState({displayBtn: 'block'});
		}else {
			this.setState({displayBtn: 'none'});
		}
	}

	closeAgencyMsgModal = () => {
		this.props.resetForm();
		this.setState({ 
			isOpen: false,
			isOpenCategoryModel:false,
			service: '',
			response: '',
			lastRecordId: '',
			lastRecord: '',
			agencyId: '',
			postalCode: '',
			categoryId: '',
			categories: [],
			isAgencyMessageModal:false,
		});
	}

	closeNoQuestionsModal = () => {
		this.props.resetForm();
		this.setState({ 
			isOpen: false,
			isOpenCategoryModel:false,
			service: '',
			response: '',
			lastRecordId: '',
			lastRecord: '',
			agencyId: '',
			postalCode: '',
			categoryId: '',
			categories: [],
			isNoQuesModalOpen:false
		});
	}

	render() {
		const styleCss = {
			'display': this.state.categoryModelDisplay ? 'block' : 'none',
		};

		const questionStyleCss = {
			'display': this.state.displayBtn,
		};
		return (
			<>
			<Button className="primary-btn btn btn-primary" onClick={this.openModal}> 
			<i className="fal fa-search"></i><span>Get quote</span>
					{this.state.isLoading 
						? (
							<span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true">
							</span>) 
						: (
							<span></span>
						)
						}
				</Button>
			
			<Modal show={this.state.isOpenCategoryModel} style={styleCss} className="quote__question_modal">
				<CategoryOptionPopup 
					state={this.state.categories}
					handler={this.openQuestionModel}
					modelQuitHandler={this.modelQuitHandler}
					closeModal={this.closeModal}
				/>
			</Modal>
			<Modal show={this.state.isOpen} style={questionStyleCss} className="quote__question_modal">
				<QuestionsPopup 
					result={this.state.response} 
					state={this.state} 
					handler={this.handler} 
					closeModal={this.closeModal}
					displayNone={this.displayNone}
				/>
			</Modal>
			<Modal show={this.state.isAgencyMessageModal} className="quote__question_modal">
				<AgencyQuoteMessagePopup user={this.state.user} closeModal={this.closeAgencyMsgModal}
				/>
			</Modal>
			<Modal show={this.state.isNoQuesModalOpen} className="quote__question_modal">
				<NoQuesModelOpen closeModal={this.closeNoQuestionsModal}/>
			</Modal>
			</>
		);
	}
}

export default App;