import React, { Component } from "react";
import { Modal } from "react-bootstrap";

export default class App extends Component {

	render() {
		return (
			<>
			<Modal.Header style={{'border': '0px'}} closeButton onClick={this.props.closeModal}></Modal.Header>
			<Modal.Body>
				<p className="text-center">We have no quote questions regarding this service.</p>
			</Modal.Body>
			</>
			);
	}
}