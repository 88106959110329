import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { getCookie } from 'cookies-next';

export default class App extends Component {

	constructor(props) {
		super(props);

		this.state = {
			user: 'agency',
		}
	}

	componentDidMount() {
		let role = getCookie('user_role');
		if(parseInt(role) === 2) {
			this.setState({user: 'agency'})
		}

		if(parseInt(role) === 1) {
			this.setState({user: 'admin'})
		}
	}

	render() {
		return (
			<>
				<Modal.Header style={{'border': '0px'}} closeButton onClick={this.props.closeModal}></Modal.Header>
				<Modal.Body>
					<p className="text-center">You are trying to get a quote from your {this.state.user} account.</p>
					<p className="text-center">You will need to logout and create a separate user account with a different email address to send and receive messages in this way.</p>
				</Modal.Body>
			</>
		);
	}
}