import React, { Component } from "react";
import { Modal, Button, } from "react-bootstrap";
import QuitQuentionPopup from './QuitQuentionPopup';
import { Input, Form, Label } from 'reactstrap';

class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
			response: '',
			categoryId: '',
			openQuitQuestionModal: false,
			categoryErr: '',
		};
		this.handleChange = this.handleChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.closeHandler = this.closeHandler.bind(this);
		this.quitPopupHandler = this.quitPopupHandler.bind(this);
	}

	componentDidMount()
	{
		this.setState({
			response: this.props.state,
		})
	}

	closeHandler(key) {
		if(key === 'quit') {
			this.props.closeModal();
		}else if(key === 'continue') {
			this.setState({openQuitQuestionModal: false})
			this.props.modelQuitHandler();

		}
	}

	quitPopupHandler() {
		this.setState({
			openQuitQuestionModal: !this.state.openQuitQuestionModal,
		});
		this.props.modelQuitHandler();
	}

	handleChange(e) {
		this.setState({categoryId: e.target.value});
	}

	componentDidUpdate(previousProps, previousState) {
		if (previousProps.state !== this.props.state) {
			this.setState({
				response: this.props.state,
			});
		}	
	}

	onSubmit()
	{
		if(this.validateForm()) {
			this.props.handler(this.state.categoryId);
		}
	}

	validateForm() {
		let categoryErr = [];
		let formIsValid = true;
		if (!this.state.categoryId) {
			formIsValid = false;
			categoryErr = "Please Select Category.";
			this.setState({categoryErr: categoryErr})
		}

		return formIsValid;
	}

	showCategories()
	{
		if(!this.state.response.length) return (<></>);
		return this.state.response.map((data) => {
			return (
				<>
				<div className="checkbox__field_c">
					<Input 
						type='radio'
						name="category_id" 
						onChange={this.handleChange} 
						value={data.id}
						id={data.id}
					/>
					<Label for={data.id}>{data.attributes.name}</Label>
				</div>
				</>
			)
		});
	}

	render() {
		return (
			<>
			<Modal.Header closeButton onClick={this.quitPopupHandler}>
			<Modal show={this.state.openQuitQuestionModal} className="quote__question_modal quit_ques_modal">
				<QuitQuentionPopup handler={this.closeHandler}/>
			</Modal>
			</Modal.Header>
				<Form>
			<Modal.Body>
				<Modal.Title className="text-center">
				Select Service 
				</Modal.Title>
				<div  className="inner_ques-wrapper">
					{this.showCategories()}
					<span className="text-danger">{this.state.categoryErr}</span>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button className="primary-btn btn btn-primary" onClick={this.onSubmit}> 
					<span>Submit</span>
				</Button>
				<div className="clearfix"></div>
			</Modal.Footer>
				</Form>
			</>
		);
	}
}

export default App;