import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
			id: '',
			name: '',
			status: false,
			isOpen: false,
		};

		this.onQuitButton = this.onQuitButton.bind(this);
	}

	componentDidMount() {
		this.setState({ isOpen: true});
	}

	closeModal = () => {
		this.props.handler('continue');
	}

	onQuitButton() {
		this.props.handler('quit');
	}
	render() {
		return (
			<>
			<Modal.Body>
				<Modal.Title className="text-center">
					Are you sure that you want to leave?
				</Modal.Title>
				<p className="description-text text-center">
					We are asking a few questions so we can find you the	right pros and send you quotes fast and free!
				</p>
			</Modal.Body>
			<Modal.Footer>
				<Button className="second-btn" onClick={this.onQuitButton}> 
					<span>Quit</span>
				</Button>
				<Button className="primary-btn" onClick={this.closeModal}>
					<span>Continue</span>
				</Button>
			</Modal.Footer>
			</>
			);
	}
}

export default App;